import { convertDateWithoutTime } from './dateFormat';

export const dateRangeWeek = (): [Date, Date] => {
  const today = new Date();
  // ✅ Get the first day of the current week (Sunday)
  const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));

  // ✅ Get the last day of the current week (Saturday)
  const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));

  return [firstDay, lastDay];
};

interface MinMaxFilterDates {
  min: Date;
  max: Date;
}
const addWeeksToDate = (dateObj: Date, numberOfWeeks: number) => {
  dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
  return dateObj;
};

export const minMaxFilterDates = (): MinMaxFilterDates => {
  return {
    min: new Date('2020-08-01T00:00:00'), // Agosto 2020
    max: addWeeksToDate(new Date(), 5),
  };
};

export const isSameDate = (date1?: Date, date2?: Date): boolean => {
  if (date1 === undefined || date2 === undefined) return false;
  date1 = convertDateWithoutTime(date1);
  date2 = convertDateWithoutTime(date2);
  return date1.getTime() === date2.getTime();
};

export const isLessOrEqualDate = (date1?: Date, date2?: Date): boolean => {
  if (date1 === undefined || date2 === undefined) return false;
  date1 = convertDateWithoutTime(date1);
  date2 = convertDateWithoutTime(date2);
  return date1.getTime() <= date2.getTime();
};

export const isBetweenDates = (date?: Date, start?: Date, end?: Date) => {
  return isLessOrEqualDate(start, date) && isLessOrEqualDate(date, end);
};

export const startOfMonth = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const endOfMonth = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const isFirstDayOfMonth = (date: Date): boolean => {
  const firstDay = startOfMonth(date);
  return isSameDate(date, firstDay);
};

export const getMonday = (date: Date): Date => {
  const d = new Date(date);
  const weekday = d.getDay();
  const diff = d.getDate() - weekday + (weekday === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

export const getSunday = (date: Date): Date => {
  const monday = getMonday(date);
  monday.setDate(monday.getDate() + 6);
  return monday;
};

export const getLastSunday = (date: Date): Date => {
  const monday = getMonday(date);
  monday.setDate(monday.getDate() - 1);
  return monday;
};

export const isExpired = (unixTimeStamp: number): boolean => {
  const expiration = new Date(unixTimeStamp * 1000);
  const now = new Date();
  return expiration.getTime() < now.getTime();
};
