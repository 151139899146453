import { Stack, SxProps, Theme, Typography } from '@mui/material';

// De necesitar adaptar el footer para alguna vista particular se puede agregar acá
type AppViews = 'roster-weekView';
interface FooterProps {
  view?: AppViews;
}

const Footer = ({ view }: FooterProps) => {
  const rosterWeekViewStyles: SxProps<Theme> = {
    position: 'absolute',
    bottom: -10,
  };
  return (
    <Stack
      component="footer"
      flexDirection="row"
      alignContent="center"
      justifyContent="center"
      padding={1}
      sx={{
        width: '100%',
        ...(view === 'roster-weekView' && rosterWeekViewStyles),
      }}
    >
      <Typography variant="body2">
        Hecho con ❤️ por &copy; Planni.Dev
      </Typography>
    </Stack>
  );
};

export default Footer;
