import { useState, type FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';
import DocsSidebar from './DocsSidebar';
import DocsNavbar from './DocsNavbar';
import { getDocsSeccion } from '../api/docs/documentation';
import { MenuDocs } from '../types/section';

const DocsLayoutWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const DocsLayoutContainer = styled('div')({
  flex: '1 1 auto',
  overflow: 'auto',
  flexGrow: 1,
});
const init = {
  sections: [],
  navbar: [],
};

const DocsLayout: FC = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);

  const [data, setData] = useState<MenuDocs>(init);

  const getSeccion = () => {
    getDocsSeccion()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          setData(init);
        }
      })
      .catch(() => {
        setData(init);
      });
  };

  useEffect(() => getSeccion(), []);

  return (
    <DocsLayoutWrapper>
      <DocsSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        sections={data.sections ?? []}
      />

      <DocsLayoutContainer>
        <DocsNavbar
          onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
          navbar={data.navbar}
        />
        <Outlet context={data.sections} />
      </DocsLayoutContainer>
    </DocsLayoutWrapper>
  );
};

export default DocsLayout;
