import { Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import ResetForm from './ResetForm';
import GenericModal from '../../shared/GenericModal';

const NewPasswordModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { palette } = useTheme();

  return (
    <Stack>
      <Typography
        fontSize={12.5}
        onClick={handleOpen}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 'auto',
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          '&:hover': {
            color: palette.primary.main,
            transition: 'all 0.3s ease-in',
          },
        }}
      >
        ¿Ha olvidado su contraseña?
      </Typography>
      <GenericModal
        open={open}
        handleClose={handleClose}
      >
        <ResetForm handleClose={handleClose} />
      </GenericModal>
    </Stack>
  );
};

export default NewPasswordModal;
