import createSvgIcon from '@mui/material/utils/createSvgIcon';

const DecorativePattern = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 161.8 161.8"
    xmlSpace="preserve"
  >
    <path
      fill="#FFF"
      d="M33.8 161.8h-1.4L0 129.4V128z"
    />
    <path
      fill="#0093B3"
      d="m0 129.4 32.4 32.4H0zM151 161.8H33.8L0 128V10.8l38.4 38.4.3.3zM161.8 161.8h-1.4L0 1.4V0z"
    />
  </svg>,
  'Patrón Decorativo'
);

export default DecorativePattern;
