import { CSSProperties } from 'react';
import { Stack, Typography } from '@mui/material';
import { ErrorIcon } from '../../../icons/material';

interface ErrorProps {
  errorMessage: string;
  iconStyle: CSSProperties;
}

const ErrorMessage = ({ errorMessage, iconStyle }: ErrorProps) => {
  return (
    <Stack>
      <Typography
        variant="h4"
        marginBottom={3}
      >
        Lo sentimos
      </Typography>
      <ErrorIcon
        color="error"
        style={iconStyle}
      />
      <Typography
        variant="body1"
        marginTop={4}
        marginX="auto"
      >
        {errorMessage}
      </Typography>
    </Stack>
  );
};

export default ErrorMessage;
