import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const GuestGuard = () => {
  const isAuthenticated = useAuth();
  const location = useLocation();
  if (isAuthenticated)
    return (
      <Navigate
        to="/"
        state={{ from: location }}
        replace
      />
    );

  return <Outlet />;
};

export default GuestGuard;
