import { CircularProgress, Stack, Typography } from '@mui/material';

const LoadingMessage = () => {
  return (
    <Stack>
      <Typography
        variant="h4"
        marginBottom={3}
      >
        Acceso
      </Typography>
      <CircularProgress
        size={80}
        thickness={3}
        sx={{
          marginX: 'auto',
        }}
      />
      <Typography
        variant="body1"
        marginTop={4}
        marginX="auto"
      >
        Estamos procesando su solicitud
      </Typography>
    </Stack>
  );
};

export default LoadingMessage;
