import { Container, Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from '../components/shared/Footer';
import Navbar from '../components/shared/Navbar';

const WeeklyAttendanceContainer = () => {
  return (
    <Container
      sx={{
        height: '100vh',
        width: '100vw',
        maxWidth: '100% !important',
        paddingX: '0px !important',
        paddingTop: '64px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Navbar />
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        <Outlet />
      </Box>
      <Footer view="roster-weekView" />
    </Container>
  );
};

export default WeeklyAttendanceContainer;
