import { FC, useEffect, useState } from 'react';
import { Container, styled } from '@mui/material';
import Markdown, { ExtraProps } from 'react-markdown';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import dracula from 'react-syntax-highlighter/dist/cjs/styles/prism/dracula';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkGemoji from 'remark-gemoji';
import { Section } from '../types/section';

const MarkdownWrapper = styled(Markdown)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& blockquote': {
    borderLeft: `4px solid ${theme.palette.text.secondary}`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    '& > p': {
      color: theme.palette.text.secondary,
      marginBottom: 0,
    },
  },
  '& code': {
    color: '#01ab56',
    fontFamily:
      "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
    fontSize: 14,
    paddingLeft: 2,
    paddingRight: 2,
  },
  '& h1': {
    fontSize: 35,
    fontWeight: 500,
    letterSpacing: '-0.24px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  '& h2': {
    fontSize: 29,
    fontWeight: 500,
    letterSpacing: '-0.24px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  '& h3': {
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: '-0.06px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  '& h4': {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.06px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  '& h5': {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '-0.05px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  '& h6': {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.05px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  '& li': {
    fontSize: 14,
    lineHeight: 1.5,
    marginBottom: theme.spacing(1),
    // marginLeft: theme.spacing(4),
  },
  '& ol, ul': {
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  '& p': {
    fontSize: 14,
    lineHeight: 1.5,
    marginBottom: theme.spacing(2),
    '& > a': {
      color: theme.palette.secondary.main,
    },
  },
  // '& img': {
  //   maxWidth: '100%',
  //   height: 'auto',
  //   borderStyle: 'none',
  // },
  // '& img[src$="#tunder"]': {
  //   maxWidth: '100%',
  //   height: 'auto',
  //   borderStyle: 'none',
  // },
  '& hr': {
    border: 0,
    borderTop: '1px solid rgba(0,0,0,.2)',
  },
}));

type Components = Partial<{
  [TagName in keyof JSX.IntrinsicElements]:
    | (new (
        props: JSX.IntrinsicElements[TagName] & ExtraProps
      ) => JSX.ElementClass)
    // Function component:
    | ((
        props: JSX.IntrinsicElements[TagName] & ExtraProps
      ) => JSX.Element | string | null | undefined)
    // Tag name:
    | keyof JSX.IntrinsicElements;
}>;

const components: Components = {
  link(props) {
    const { href, children } = props;
    if (href && !href.startsWith('http')) {
      return <a href={href}>{children}</a>;
    }
    return (
      <a
        href={href}
        rel="nofollow noreferrer noopener"
        target="_blank"
      >
        {children}
      </a>
    );
  },
  img(props) {
    const { alt, src, ...other } = props;
    if (
      // src?.includes('#cover') ||
      // src?.includes('#banner') ||
      // src?.includes('#logo') ||
      // src?.includes('#avatar') ||
      // src?.includes('#icon') ||
      src?.includes('#image')
    ) {
      return (
        <img
          alt={alt}
          src={src}
          width="100%"
          style={{
            maxWidth: '100%',
            height: 'auto',
            borderStyle: 'none',
          }}
        />
      );
    }
    if (src?.includes('#thumbnail')) {
      return (
        <img
          alt={alt}
          src={src}
          {...other}
          style={{
            maxWidth: '100%',
            height: 'auto',
            borderStyle: 'none',
          }}
        />
      );
    }
    return (
      <img
        alt={alt}
        src={src}
        {...other}
        style={{
          maxWidth: '100%',
          height: 'auto',
          borderStyle: 'none',
        }}
      />
    );
  },
  code(props) {
    const { children, className, node, ...other } = props;
    const match = /language-(\w+)/.exec(className || '');
    const replaced = String(children).replace(/\n$/, '');
    return match ? (
      <SyntaxHighlighter
        PreTag="div"
        language={match[1]}
        style={dracula}
      >
        {replaced}
      </SyntaxHighlighter>
    ) : (
      <code
        {...other}
        className={className}
      >
        {children}
      </code>
    );
  },
};

const DocsDemo: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [name] = useOutletContext<Section[]>();
  const [file, setFile] = useState<string | null>(null);

  console.log(name);
  useEffect(() => {
    const getFile = async () => {
      try {
        if (!pathname.startsWith('/docs')) {
          navigate('/404', { replace: true });
          return;
        }
        const response = await fetch(`${pathname}.md`, {
          headers: {
            accept: 'text/markdown', // Do not accept anything else
          },
        });

        if (response.status !== 200) {
          navigate(response.status === 404 ? '/404' : '/500', {
            replace: true,
          });
          return;
        }

        const data = await response.text();

        setFile(data);
      } catch (err) {
        console.error(err);
        navigate('/500');
      }
    };

    getFile().catch((err) => {
      console.error(err);
      navigate('/500');
    });
  }, [pathname]);

  if (!file) {
    return null;
  }

  return (
    <Container maxWidth="md">
      <MarkdownWrapper
        remarkPlugins={[[remarkGemoji, remarkGfm, { singleTilde: false }]]}
        rehypePlugins={[rehypeRaw]}
        components={components}
      >
        {file}
      </MarkdownWrapper>
    </Container>
  );
};

export default DocsDemo;
