import axios, { AxiosError, AxiosInstance } from 'axios';
import { apiUserManagerURL, mockAndDevelopment } from '../../app.config';

const userManagerClient: AxiosInstance = mockAndDevelopment()
  ? axios.create()
  : axios.create({
      baseURL: apiUserManagerURL(),
      headers: {
        accept: '*/*',
        contentType: 'application/json',
      },
      paramsSerializer: {
        indexes: null,
      },
    });

export const add401Interceptor = (logoutFunct: () => void) => {
  userManagerClient.interceptors.response.use(undefined, (err: AxiosError) => {
    if (err.response?.status === 401) {
      logoutFunct();
    }
    return Promise.reject(err);
  });
};

export default userManagerClient;
