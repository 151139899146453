import { useContext } from 'react';
import ColorModeContext from '../contexts/theme/ColorModeProvider';
import { ColorModeContextValue } from '../types/colorMode';

export const useColorMode = (): ColorModeContextValue['mode'] => {
  const { mode } = useContext(ColorModeContext);
  return mode;
};

export const useChangeMode = (): ColorModeContextValue['changeMode'] => {
  const { changeMode } = useContext(ColorModeContext);
  return changeMode;
};
