export enum PermissionLevel {
  None = '0',
  ReadOnly = '1',
  ReadAndWrite = '2',
  SuperUser = '3',
}

// * Permisos mínimos para acceder a una vista
export enum MinimumPermission {
  ReadOnly = PermissionLevel.ReadOnly,
  ReadAndWrite = PermissionLevel.ReadAndWrite,
  SuperUser = PermissionLevel.SuperUser,
}

export const permissionsToRead = [
  PermissionLevel.ReadOnly,
  PermissionLevel.ReadAndWrite,
  PermissionLevel.SuperUser,
];
export const permissionsToWrite = [
  PermissionLevel.ReadAndWrite,
  PermissionLevel.SuperUser,
];
