import { PaletteOptions } from '@mui/material';
import customColors from './customColors';

const lightMode: PaletteOptions = {
  primary: {
    main: customColors.metbusPrimary[500],
    light: customColors.metbusPrimary[400],
  },
  secondary: {
    main: customColors.metbusSecondary[500],
    light: customColors.metbusSecondary[100],
    dark: customColors.metbusSecondary[600],
  },
  text: {
    primary: customColors.text[500],
  },
  background: {
    default: customColors.background[500],
    paper: '#FFFFFF',
    // disable: customColors.background[300],
    disable: '#e0f2f6',
  },
  gadget: {
    main: customColors.metbusPrimary[300],
  },
  icons: {
    morning: {
      fill: customColors.yellow[500],
      background: customColors.yellow[100],
    },
    afternoon: {
      fill: customColors.metbusSecondary[500],
      background: customColors.metbusSecondary[100],
    },
    night: {
      fill: customColors.blue[500],
      background: customColors.blue[100],
    },
  },
};

export default lightMode;
