import {
  MinimumPermission,
  PermissionLevel,
  permissionsToRead,
  permissionsToWrite,
} from '../types/claims/profilePermission';

export const selectPermissions = (
  minimumPermission: MinimumPermission | undefined
): PermissionLevel[] | undefined => {
  switch (minimumPermission) {
    case MinimumPermission.ReadOnly:
      return permissionsToRead;
    case MinimumPermission.ReadAndWrite:
      return permissionsToWrite;
    case MinimumPermission.SuperUser:
      return [PermissionLevel.SuperUser];
    default:
      return undefined;
  }
};
