import axios, { AxiosInstance } from 'axios';
import { apiRosterURL, mockAndDevelopment } from '../../app.config';
import { httpErrorNotification } from '../httpErrorNotification';

const rosterClient: AxiosInstance = mockAndDevelopment()
  ? axios.create()
  : axios.create({
      baseURL: apiRosterURL(),
      headers: {
        accept: '*/*',
        contentType: 'application/json',
      },
      paramsSerializer: {
        indexes: null,
      },
    });

rosterClient.interceptors.response.use(undefined, httpErrorNotification);

export default rosterClient;
