import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { CSSProperties, useState } from 'react';
import * as Yup from 'yup';
import { Button, Stack, TextField, Typography } from '@mui/material';
import ErrorMessage from './ErrorMessage';
import LoadingMessage from './LoadingMessage';
import resetPassword from '../../../api/userManager/resetPassword';
import { CheckCircleRoundedIcon } from '../../../icons/material';

interface ResetFormProps {
  handleClose: () => void;
}

const iconStyle: CSSProperties = {
  aspectRatio: '1/1',
  margin: 'auto',
  minHeight: '200px',
  minWidth: '200px',
};

const ResetForm = ({ handleClose }: ResetFormProps) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: { userName: '' },
    validationSchema: Yup.object({
      userName: Yup.string().required('Debe ingresar su nombre de usuario'),
    }),
    onSubmit: ({ userName }) => {
      setLoading(true);
      resetPassword(userName.toUpperCase())
        .then(({ status }) => {
          if (status === 200) {
            setErrorMessage('');
            setSuccess(true);
          } else {
            setErrorMessage(
              'Ha ocurrido un error inesperado. Intente más tarde'
            );
            setSuccess(false);
          }
        })
        .catch((err: AxiosError) =>
          setErrorMessage(err.response?.data as string)
        )
        .finally(() => setLoading(false));
    },
  });

  if (loading) return <LoadingMessage />;

  if (errorMessage !== '')
    return (
      <ErrorMessage
        errorMessage={errorMessage}
        iconStyle={iconStyle}
      />
    );

  if (success) {
    return (
      <Stack>
        <Typography
          variant="h4"
          marginBottom={3}
        >
          ¡Contraseña Enviada!
        </Typography>
        <CheckCircleRoundedIcon
          color="primary"
          style={iconStyle}
        />
        <Typography
          variant="body1"
          marginTop={4}
          marginX="auto"
        >
          Su nueva contraseña ha sido enviada a su correo electrónico
        </Typography>
        <Button
          color="primary"
          fullWidth
          size="large"
          type="button"
          variant="contained"
          onClick={handleClose}
          sx={{ marginTop: 2 }}
        >
          Iniciar Sesión
        </Button>
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography
        variant="h4"
        marginBottom={3}
      >
        Solicitud
      </Typography>
      <Typography variant="body1">
        Para recibir una nueva contraseña en su correo electrónico sólo debe
        ingresar su usuario con el que accede a la plataforma:
      </Typography>
      <form
        onSubmit={formik.handleSubmit}
        style={{ position: 'relative' }}
        noValidate
        autoComplete="off"
      >
        <TextField
          name="userName"
          label="Usuario"
          value={formik.values.userName}
          onChange={formik.handleChange}
          autoComplete="off"
          autoFocus
          fullWidth
          margin="normal"
          type="text"
          variant="outlined"
        />
        <Button
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ marginTop: '18px' }}
        >
          Enviar Contraseña
        </Button>
      </form>
    </Stack>
  );
};

export default ResetForm;
