import { useContext } from 'react';
import { AuthContext } from '../contexts/authentication/AuthContextProvider';
import { AppUser } from '../types/responses/loginResponse';

export const useAuth = () => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated;
};

export const useAuthUser = (): AppUser | null => {
  const { user } = useContext(AuthContext);
  return user;
};

export const useAuthLogin = () => {
  const { login } = useContext(AuthContext);
  return login;
};

export const useAuthLogout = () => {
  const { logout } = useContext(AuthContext);
  return logout;
};

export const useAuthInitialized = (): boolean => {
  const { isInitialized } = useContext(AuthContext);
  return isInitialized;
};
