import { PaletteOptions } from '@mui/material';
import customColors from './customColors';

const darkMode: PaletteOptions = {
  primary: {
    main: customColors.metbusPrimary[500],
  },
  secondary: {
    main: customColors.metbusSecondary[500],
    light: customColors.metbusSecondary[200],
  },
  text: {
    primary: customColors.text[500],
  },
  background: {
    default: customColors.background[900],
    paper: '#FFFFFF',
  },
  gadget: {
    main: customColors.metbusPrimary[400],
  },
  icons: {
    morning: {
      fill: customColors.yellow[500],
      background: customColors.yellow[100],
    },
    afternoon: {
      fill: customColors.metbusSecondary[500],
      background: customColors.metbusSecondary[100],
    },
    night: {
      fill: customColors.blue[500],
      background: customColors.blue[100],
    },
  },
};

export default darkMode;
