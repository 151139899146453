import { createTheme, PaletteMode, Theme } from '@mui/material';
import { esES } from '@mui/x-data-grid';
import pulseAnimation from '../CSS/pulseAnimation';
import customColors from './customColors';
import darkMode from './darkMode';
import lightMode from './lightMode';
import lightShadows, { LightShadows } from './shadows';

export const defaultMode: PaletteMode = 'light';

export enum BorderRadius {
  Card = '10px',
  Filter = '5px',
}

const createCustomTheme = (mode: PaletteMode): Theme => {
  if (!mode) mode = defaultMode;
  const theme = createTheme(
    {
      palette: {
        mode,
        ...(mode === 'dark' ? darkMode : lightMode),
      },
      direction: 'ltr',
      typography: {
        fontFamily: [
          'Rubik',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          '"Helvetica"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
        ].join(','),
        button: {
          fontWeight: 600,
        },
        h1: {
          fontWeight: 600,
          fontSize: '3.5rem',
        },
        h2: {
          fontWeight: 600,
          fontSize: '3rem',
        },
        h3: {
          fontWeight: 600,
          fontSize: '2.25rem',
        },
        h4: {
          fontWeight: 600,
          fontSize: '2rem',
        },
        h5: {
          fontWeight: 600,
          fontSize: '1.5rem',
        },
        h6: {
          fontWeight: 600,
          fontSize: '1.125rem',
        },
        subtitle2: {
          // Utilizar en Typography que sean títulos de las Card pequeñas
          fontWeight: 500,
          fontSize: '0.875rem',
        },
        body2: {
          // Utilizar en Typography que estén en el cuerpo de las Cards pequeñas
          fontWeight: 400,
          fontSize: '0.875rem',
        },
        overline: {
          fontWeight: 600,
        },
      },
      shadows: {
        ...lightShadows,
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              // color:
              //   mode === 'light'
              //     ? customColors.text[500]
              //     : customColors.background[100],
              color: customColors.text[500],
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              boxShadow: LightShadows.None,
              borderRadius: BorderRadius.Filter,
            },
          },
        },
        MuiCardHeader: {
          defaultProps: {
            titleTypographyProps: {
              variant: 'h4',
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: BorderRadius.Card,
              overflow: 'hidden',
              boxShadow: LightShadows.Main,
              '&:hover': {
                boxShadow: LightShadows.Hover,
              },
            },
          },
        },
        MuiLinearProgress: {
          styleOverrides: {
            root: {
              borderRadius: 3,
              overflow: 'hidden',
            },
          },
        },
        MuiAvatar: {
          styleOverrides: {
            fallback: {
              height: '75%',
              width: '75%',
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: '35px',
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              paddingInline: '8px',
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: BorderRadius.Filter,
              backgroundColor: '#FFFFFF',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #0093B3',
              },
              '&& .MuiAutocomplete-endAdornment > button > svg': {
                fill: '#0093B3',
              },
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              textDecoration: 'none',
              transition: 'all 0.3s ease-in',
              '&:hover': {
                transition: 'all 0.3s ease-in',
                animation: `${pulseAnimation} 0.5s ease`,
              },
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              boxShadow: LightShadows.None,
            },
          },
        },
      },
    },
    esES
  );

  return theme;
};

export default createCustomTheme;
