import { useFormik } from 'formik';
import { useState } from 'react';
import { object, string } from 'yup';
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import pulseAnimation from '../../../CSS/pulseAnimation';
import { mockAndDevelopment } from '../../../app.config';
import { useAuthLogin } from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { VisibilityIcon, VisibilityOffIcon } from '../../../icons/material';

const LoginForm = () => {
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const handlePasswordVisibility = () => {
    setHiddenPassword((prevState) => !prevState);
  };
  const isMountedRef = useIsMountedRef();
  const login = useAuthLogin();
  const formik = useFormik({
    initialValues: {
      userName: mockAndDevelopment() ? '12345678-9' : '',
      password: mockAndDevelopment() ? '1234' : '',
    },
    validationSchema: object({
      userName: string().required('Ingrese su usuario'),
      password: string().required('Ingrese su contraseña'),
    }),
    onSubmit: async (
      values,
      { setErrors, setStatus, setSubmitting }
    ): Promise<void> => {
      setLoading(true);
      try {
        const success = await login(values.userName, values.password);

        if (isMountedRef()) {
          setStatus({ success });
          if (!success) setErrors({ password: 'Contraseña incorrecta' });
          setSubmitting(false);
        }
      } catch (err) {
        if (isMountedRef()) {
          setStatus({ success: false });
          setErrors({
            userName: 'Ingrese un usuario válido',
            password: 'Ingrese una constraseña válida',
          });
          setSubmitting(false);
        }
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <form
      action="submit"
      onSubmit={formik.handleSubmit}
      noValidate
      autoComplete="off"
    >
      <div style={{ position: 'relative' }}>
        <TextField
          autoFocus
          fullWidth
          label="Usuario"
          margin="normal"
          name="userName"
          onChange={formik.handleChange}
          type="text"
          variant="outlined"
          autoComplete="off"
          value={formik.values.userName}
          sx={{
            marginBottom: '20px',
          }}
        />
        {formik.touched.userName && formik.errors.userName && (
          <Typography
            color="error"
            variant="caption"
            sx={{
              position: 'absolute',
              left: '5px',
              top: '75px',
              animation: `${pulseAnimation} 0.5s ease`,
            }}
          >
            {formik.errors.userName}
          </Typography>
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <TextField
          fullWidth
          label="Contraseña"
          margin="normal"
          name="password"
          onChange={formik.handleChange}
          type={hiddenPassword ? 'password' : 'text'}
          variant="outlined"
          autoComplete="off"
          value={formik.values.password}
          sx={{
            marginBottom: '20px',
          }}
        />
        <Button
          onClick={handlePasswordVisibility}
          onTouchEnd={(e) => {
            e.preventDefault();
            handlePasswordVisibility();
          }}
          sx={{
            position: 'absolute',
            top: '25px',
            right: '5px',
            minWidth: 'fit-content',
            padding: 1,
            borderRadius: 50,
          }}
        >
          {hiddenPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </Button>
        {formik.touched.password && formik.errors.password && (
          <Typography
            color="error"
            variant="caption"
            sx={{
              position: 'absolute',
              left: '5px',
              top: '75px',
              animation: `${pulseAnimation} 0.5s ease`,
            }}
          >
            {formik.errors.password}
          </Typography>
        )}
      </div>

      <Box sx={{ m: 1, position: 'relative', marginTop: '1rem' }}>
        <Button
          disabled={loading}
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Iniciar Sesión
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </form>
  );
};

export default LoginForm;
