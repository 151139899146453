import { AxiosResponse } from 'axios';
import { systemId } from '../app.config';
import { IdentityResponse, TokenData } from '../types/responses/loginResponse';
import userManagerClient from './clients/userManagerClient';

export interface LoginParameters {
  userName: string;
  password: string;
}

export const RequestAuthorization = (): Promise<AxiosResponse<TokenData>> => {
  return userManagerClient.get<TokenData>('/api/Token/RequestAuthorization', {
    params: { systemId },
    withCredentials: true,
  });
};

export const RequestTokenWithPermissions = async (
  params: LoginParameters
): Promise<string> => {
  try {
    const { data: identity } = await userManagerClient.post<IdentityResponse>(
      '/api/Token/RequestIdentity',
      params,
      { withCredentials: true }
    );
    localStorage.setItem('identityData', JSON.stringify(identity.identity));

    const { data: authorization } = await RequestAuthorization();
    localStorage.setItem('authorizationToken', authorization.token);
    return authorization.token;
  } catch (err: any) {
    return '';
  }
};
