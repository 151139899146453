import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { adapterLocale } from './app.config';
import MetbusSplashScreen from './components/shared/loaders/MetbusSplashScreen';
import { useAuthInitialized } from './hooks/useAuth';
import { useColorMode } from './hooks/useColorMode';
import useScrollReset from './hooks/useScrollReset';
import routes from './routes';
import createCustomTheme from './theme';
import { SnackbarUtilsConfigurator } from './utils/snackbarUtilsConfigurator';

const App = () => {
  const isInitialized = useAuthInitialized();
  const content = useRoutes(routes);
  const mode = useColorMode();
  const theme = createCustomTheme(mode);

  useScrollReset();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        dense
        autoHideDuration={3000}
        preventDuplicate
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <SnackbarUtilsConfigurator />
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={adapterLocale}
        >
          {isInitialized ? content : <MetbusSplashScreen />}
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
