import { Card, CardContent, Stack, Typography, Divider } from '@mui/material';
import LoginForm from '../components/authentication/login/LoginForm';
import DecorativePattern from '../icons/DecorativePattern';
import MetbusLogo from '../icons/MetbusLogo';
import NewPasswordModal from '../components/authentication/login/NewPasswordModal';

const Login = () => {
  return (
    <>
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          '@media screen and (max-width: 770px)': {
            flexDirection: 'column',
          },
        }}
      >
        <MetbusLogo width={250} />
        <Card
          sx={{
            width: '350px',
            height: 'auto',
            boxShadow: 1,
            transition: 'all 400ms ease',
            '&:hover': {
              transition: 'all 400ms ease-in',
              boxShadow: 2,
            },
            '@media screen and (max-width: 374px)': {
              width: '260px',
            },
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
              gap: '1rem',
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              Inicio de Sesión
            </Typography>
            <LoginForm />
            <Divider />
            <NewPasswordModal />
          </CardContent>
        </Card>
      </Stack>
      <DecorativePattern
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          aspectRatio: '1/1',
          width: 'calc(20% + 100px)',
          height: 'auto',
        }}
      />
      <DecorativePattern
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          transform: 'rotate(180deg)',
          aspectRatio: '1/1',
          width: 'calc(20% + 30px)',
          height: 'auto',
        }}
      />
    </>
  );
};

export default Login;
