import { AxiosResponse } from 'axios';
import userManagerClient from '../clients/userManagerClient';

const resetPassword = (userName: string): Promise<AxiosResponse<void>> =>
  userManagerClient.put(
    '/api/User/ResetPasswordUser',
    {},
    { params: { userName } }
  );

export default resetPassword;
