import { MouseEvent, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Sidebar from './Sidebar';
import MetbusLogo from '../../icons/MetbusLogo';
import { avatarName, capitalizeText } from '../../utils/stringFormat';
import { useAuthLogout, useAuthUser } from '../../hooks/useAuth';
import { KeyIcon, LogoutIcon } from '../../icons/material';
import GenericModal from './GenericModal';
import ChangePasswordForm from '../authentication/login/ChangePasswordForm';
import { MeResponse } from '../../types/responses/loginResponse';

const Navbar = () => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  const user = useAuthUser();
  const logout = useAuthLogout();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (_e: MouseEvent<HTMLElement>) =>
    setAnchorEl(_e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [openModal, setOpenModal] = useState(false);
  const openChangePassword = () => {
    handleClose();
    setOpenModal(true);
  };
  const closeChangePassword = () => setOpenModal(false);
  const identityData = JSON.parse(
    localStorage.getItem('identityData') ?? ''
  ) as MeResponse;

  return (
    <>
      <AppBar
        position="fixed"
        role="navigation"
        sx={{
          top: 0,
          boxShadow: 1,
          backgroundColor: main,
          height: '58px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 10,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
          >
            <Sidebar />
            <a href="/">
              <MetbusLogo
                contrastColor={false}
                width={120}
              />
            </a>
          </Stack>
          <Stack>
            <Box>
              <Tooltip title="Menú de Usuario">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar sx={{ bgcolor: '#B95CF4' }}>
                    {avatarName(identityData.name)}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '85%',
                  marginInline: 'auto',
                  marginBottom: 2,
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <Avatar sx={{ bgcolor: '#B95CF4' }}>
                  {avatarName(identityData.name)}
                </Avatar>
                <Box sx={{ ml: 2 }}>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {capitalizeText(identityData.name.toLowerCase())}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {user?.systemClaims?.profileName}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <MenuItem>
                <ListItemButton
                  sx={{
                    borderRadius: 1,
                    '&:hover:nth-of-type(n+1) path': {
                      color: main,
                    },
                    '&:hover:nth-of-type(n+1) p': {
                      color: main,
                    },
                  }}
                  onClick={() => openChangePassword()}
                >
                  <ListItemIcon>
                    <KeyIcon
                      sx={{
                        aspectRatio: '1/1',
                        width: '32px',
                        height: 'auto',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary="Cambiar Contraseña" />
                </ListItemButton>
              </MenuItem>
              <MenuItem>
                <ListItemButton
                  sx={{
                    borderRadius: 1,
                    '&:hover:nth-of-type(n+1) path': {
                      color: main,
                    },
                    '&:hover:nth-of-type(n+1) p': {
                      color: main,
                    },
                  }}
                  onClick={() => logout()}
                >
                  <ListItemIcon>
                    <LogoutIcon
                      sx={{
                        aspectRatio: '1/1',
                        width: '32px',
                        height: 'auto',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary="Cerrar Sesión" />
                </ListItemButton>
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </AppBar>
      <GenericModal
        open={openModal}
        handleClose={closeChangePassword}
      >
        <ChangePasswordForm handleClose={closeChangePassword} />
      </GenericModal>
    </>
  );
};

export default Navbar;
