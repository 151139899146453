import {
  ActionType,
  InitializeAction,
  LoginAction,
  LogoutAction,
} from '../../types/authContextActions';

export const initAuthAction = (
  user: InitializeAction['payload']['user'],
  isAuthenticated: InitializeAction['payload']['isAuthenticated']
): InitializeAction => ({
  type: ActionType.INITIALIZE,
  payload: {
    isAuthenticated,
    user,
  },
});

export const loginAction = (
  user: LoginAction['payload']['user']
): LoginAction => ({
  type: ActionType.LOGIN,
  payload: {
    user,
  },
});

export const logoutAction = (): LogoutAction => ({
  type: ActionType.LOGOUT,
});
