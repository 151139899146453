import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { isMobile } from '../../app.config';
import {
  AppModule,
  AppSection,
  useSidebarSections,
} from '../../hooks/useSidebarSections';
import MetbusLogo from '../../icons/MetbusLogo';
import { MenuIcon, ExpandLessIcon, ExpandMoreIcon } from '../../icons/material';
import {
  ControlPanelRoute,
  ExchangeRoute,
  MaintainerRoute,
  MetbusAppRoute,
  RosterRoute,
  UserManagerRoute,
  MailingRoute,
} from '../../types/appRoutes';

interface ModuleProps
  extends AppModule<
    | MetbusAppRoute
    | RosterRoute
    | ExchangeRoute
    | MaintainerRoute
    | ControlPanelRoute
    | UserManagerRoute
    | MailingRoute
  > {
  handleNavigation: (path: string) => void;
}

const Module = ({
  title: moduleTitle,
  route: path,
  icon: moduleIcon,
  handleNavigation,
}: ModuleProps) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  return (
    <ListItemButton
      key={moduleTitle}
      sx={{
        borderRadius: 1,
        '&:hover:nth-of-type(n+1) path': {
          color: main,
        },
        '&:hover:nth-of-type(n+1) p': {
          color: main,
        },
      }}
      onClick={() => handleNavigation(path)}
    >
      <ListItemIcon>{moduleIcon}</ListItemIcon>
      <ListItemText secondary={moduleTitle} />
    </ListItemButton>
  );
};

interface SectionProps
  extends Pick<
    AppSection<RosterRoute | ExchangeRoute | MaintainerRoute>,
    'title' | 'icon'
  > {
  children: EmotionJSX.Element | EmotionJSX.Element[];
  sx?: SxProps<Theme>;
}

const NavSection = ({
  title: sectionTitle,
  icon: sectionIcon,
  children,
  sx,
}: SectionProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          borderRadius: 1,
          '&:hover:nth-of-type(n+1) path': {
            color: main,
          },
          '&:hover:nth-of-type(n+1) p': {
            color: main,
          },
        }}
      >
        <ListItemIcon>{sectionIcon}</ListItemIcon>
        <ListItemText secondary={sectionTitle} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
          sx={sx}
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};

const Sidebar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();
  const {
    exchanges,
    maintainers,
    metbusApps,
    metbusDocs,
    roster,
    control,
    userManager,
    mailing,
  } = useSidebarSections();

  const openSideBar =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpenSidebar(open);
    };

  const handleClickNavigation = (path: string) => {
    navigate(path);
    setOpenSidebar(false);
  };

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={openSideBar(true)}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor="left"
        open={openSidebar}
        onClose={openSideBar(false)}
        role="presentation"
        variant="temporary"
        sx={{
          boxShadow: 1,
          '& .MuiDrawer-paper': {
            width: '250px',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: '1rem',
            borderTopRightRadius: 10,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              position="relative"
            >
              <MetbusLogo
                contrastColor
                width={120}
              />
            </Stack>
            <Divider
              sx={{
                backgroundClip: main,
              }}
            />
          </Box>

          <Box
            onKeyDown={openSideBar(false)}
            flexGrow={1}
          >
            <List role="menu">
              <Module
                title={metbusApps.title}
                icon={metbusApps.icon}
                route={metbusApps.route}
                handleNavigation={handleClickNavigation}
              />

              {roster && (
                <NavSection
                  title={roster.title}
                  icon={roster.icon}
                  sx={{ pl: 4 }}
                >
                  {roster.modules
                    .filter(
                      ({ route }) =>
                        !isMobile || route !== '/roster/carga-de-programacion'
                    )
                    .map(({ icon, route: path, title }) => (
                      <Module
                        key={path}
                        title={title}
                        icon={icon}
                        route={path}
                        handleNavigation={handleClickNavigation}
                      />
                    ))}
                </NavSection>
              )}
              {exchanges && (
                <Module
                  title={exchanges.title}
                  icon={exchanges.icon}
                  route={exchanges.route}
                  handleNavigation={handleClickNavigation}
                />
              )}
              {maintainers && (
                <NavSection
                  title={maintainers.title}
                  icon={maintainers.icon}
                  sx={{ pl: 4 }}
                >
                  {maintainers.modules.map(({ icon, route: path, title }) => (
                    <Module
                      key={path}
                      title={title}
                      icon={icon}
                      route={path}
                      handleNavigation={handleClickNavigation}
                    />
                  ))}
                </NavSection>
              )}
              {control && (
                <NavSection
                  title={control.title}
                  icon={control.icon}
                  sx={{ pl: 4 }}
                >
                  {control.modules.map(({ icon, route: path, title }) => (
                    <Module
                      key={path}
                      title={title}
                      icon={icon}
                      route={path}
                      handleNavigation={handleClickNavigation}
                    />
                  ))}
                </NavSection>
              )}
              {userManager && (
                <NavSection
                  title={userManager.title}
                  icon={userManager.icon}
                  sx={{ pl: 4 }}
                >
                  {userManager.modules.map(({ icon, route: path, title }) => (
                    <Module
                      key={path}
                      title={title}
                      icon={icon}
                      route={path}
                      handleNavigation={handleClickNavigation}
                    />
                  ))}
                </NavSection>
              )}
              {mailing && (
                <NavSection
                  title={mailing.title}
                  icon={mailing.icon}
                  sx={{ pl: 4 }}
                >
                  {mailing.modules.map(({ icon, route: path, title }) => (
                    <Module
                      key={path}
                      title={title}
                      icon={icon}
                      route={path}
                      handleNavigation={handleClickNavigation}
                    />
                  ))}
                </NavSection>
              )}
              <Module
                title={metbusDocs.title}
                icon={metbusDocs.icon}
                route={metbusDocs.route}
                handleNavigation={handleClickNavigation}
              />
            </List>
            <Divider />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
