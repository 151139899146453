import { AxiosResponse } from 'axios';
import userManagerClient from '../clients/userManagerClient';

interface ChangePasswordProps {
  userName: string;
  currentPassword: string;
  newPassword: string;
}

const changePassword = (
  body: ChangePasswordProps
): Promise<AxiosResponse<void>> =>
  userManagerClient.put('/api/User/ChangePassword', body);

export default changePassword;
