import rosterClient from './clients/rosterClient';
import rrhhClient from './clients/rrhhClient';
import sgigoClient from './clients/sgigoClient';
import userManagerClient from './clients/userManagerClient';
import mailingClient from './clients/mailingClient';

const setAuthorizationToken = (authorizationToken: string | null) => {
  if (authorizationToken) {
    rosterClient.defaults.headers.common.Authorization = `Bearer ${authorizationToken}`;
    rrhhClient.defaults.headers.common.Authorization = `Bearer ${authorizationToken}`;
    sgigoClient.defaults.headers.common.Authorization = `Bearer ${authorizationToken}`;
    userManagerClient.defaults.headers.common.Authorization = `Bearer ${authorizationToken}`;
    mailingClient.defaults.headers.common.Authorization = `Bearer ${authorizationToken}`;
  } else {
    delete rosterClient.defaults.headers.common.Authorization;
    delete rrhhClient.defaults.headers.common.Authorization;
    delete sgigoClient.defaults.headers.common.Authorization;
    delete userManagerClient.defaults.headers.common.Authorization;
    delete mailingClient.defaults.headers.common.Authorization;
  }
};

export default setAuthorizationToken;
