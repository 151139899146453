import { capitalizeText } from './stringFormat';
import { adapterLocale } from '../app.config';

// * input: 2022-12-05T17:44:01.000Z
// * output: 05-12-2022
export const dateUtcToChile = (date: Date): string =>
  new Date(date)
    .toLocaleString(adapterLocale, { timeZone: 'America/Santiago' })
    .split(', ')[0];

// * Recibe un string o un objeto de fecha exótico
// * Ejemplo: "Sat Mar 04 2023 00:00:45 GMT-0300 (hora de verano de Chile)"
export const formatDateToHoursAndMinutes = (input: Date | string): string => {
  if (typeof input === 'object') {
    const hours = new Date(input).getHours();
    const minutes = new Date(input).getMinutes();
    const hoursFormat = hours < 10 ? `0${hours}` : hours;
    const minutesFormat = minutes < 10 ? `0${minutes}` : minutes;
    return `${hoursFormat}:${minutesFormat}`;
  }
  return input;
};

// * input: "06/07/2023"
// * output: Date(2023, 6, 6)
export const localeStringtoDate = (date: string): Date => {
  const dateParts = date.split('/');
  return new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );
};

// * input: Date(2023, 8, 6)
// * output: 2023
export const getYYYY = (date: Date): string =>
  date.toLocaleString(adapterLocale, { year: 'numeric' });

// * input: Date(2023, 8, 6)
// * output: 23
export const getYY = (date: Date): string =>
  date.toLocaleString(adapterLocale, { year: '2-digit' });

// * input: Date(2023, 8, 6)
// * output: septiembre
export const getMMMM = (date: Date): string =>
  date.toLocaleString(adapterLocale, { month: 'long' });

// * input: Date(2023, 8, 6)
// * output: Sep
export const getMMM = (date: Date): string =>
  date.toLocaleString(adapterLocale, { month: 'short' }).replaceAll('.', '');

// * input: Date(2023, 8, 6)
// * output: 09
export const getMM = (date: Date): string =>
  date.toLocaleString(adapterLocale, { month: '2-digit' });

// * input: Date(2023, 8, 6)
// * output: 06
export const getDD = (date: Date): string =>
  date.toLocaleString(adapterLocale, { day: '2-digit' });

// * input: Date(2023, 8, 6)
// * output: 6
export const getD = (date: Date): number => date.getDate();

// * input: Date(2023, 8, 6)
// * output: miércoles
export const getdddd = (date: Date): string =>
  date.toLocaleString(adapterLocale, { weekday: 'long' });

// * input Date(2023, 9, 26)
// * output "Octubre 2023"
export const formatMonthYear = (date: Date): string => {
  const year = getYYYY(date);
  const month = getMMMM(date);
  return `${capitalizeText(month)} ${year} `;
};

// * input: Date(2023, 6, 10)
// * output: "Lunes 10, Julio 2023"
export const dateToHumanReadableString = (date: Date): string => {
  const year = getYYYY(date);
  const month = getMMMM(date);
  const day = getDD(date);
  const weekday = getdddd(date);
  return capitalizeText(`${weekday} ${day}, ${month} ${year}`);
};

// * input: Date(2023, 6, 10)
// * output: "10/07/2023"
export const formatDDMMYYYY = (date: Date, divider = '/'): string => {
  const year = getYYYY(date);
  const month = getMM(date);
  const day = getDD(date);
  return `${day}${divider}${month}${divider}${year}`;
};

// * input: Date(2023, 6, 10)
// * output: "2023/07/10"
export const formatYYYYMMDD = (date: Date, divider = '/'): string => {
  const year = getYYYY(date);
  const month = getMM(date);
  const day = getDD(date);
  return `${year}${divider}${month}${divider}${day}`;
};

export const formatMMYY = (date: Date, divider = '/'): string => {
  const year = getYY(date);
  const month = getMM(date);
  return `${month}${divider}${year}`;
};

// * input: Date(2023, 6, 1)
// * output: "Jul/1"
export const formatFirstDayOfMonth = (date: Date): string => {
  const month = getMMM(date);
  const day = getD(date);
  return `${month}/${day}`;
};

// * input: Date(2023, 6, 7)
// * output: "07/07"
export const formatDDMM = (date: Date, divider = '/'): string => {
  return date
    .toLocaleString(adapterLocale, {
      day: '2-digit',
      month: '2-digit',
    })
    .replaceAll('-', divider);
};

// * input: Date(2023, 6, 10)
// * output: "Julio 2023"
export const formatMMMMYYYY = (date: Date): string => {
  const year = getYYYY(date);
  const month = getMMMM(date);
  return `${month} ${year}`;
};

// * input: Date(2023, 6, 10)
// * output: "10/07/23"
export const formatDDMMYY = (date: Date, divider = '/'): string => {
  return date
    .toLocaleString(adapterLocale, {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })
    .replaceAll('-', divider);
};

export const formatWindowsTimeStamp = (date: Date): string => {
  const year = getYYYY(date);
  const month = getMM(date);
  const day = getDD(date);
  const time = date
    .toLocaleDateString(adapterLocale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replaceAll(':', '_');
  return `${year}_${month}_${day}_${time}`;
};

export const formatExcel = (date: Date): string =>
  date.toLocaleString(adapterLocale).replaceAll(',', '').replaceAll('-', '/');

export const convertDateWithoutTime = (date: Date) =>
  new Date(date.setHours(0, 0, 0, 0));

export const convertDateOnlyToDate = (data: Date | string) => {
  const dateOnly = data.toString().split('T')[0];
  const parts = dateOnly.toString().split('-');
  return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
};
