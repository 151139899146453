import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthUser } from '../../hooks/useAuth';
import {
  MinimumPermission,
  PermissionLevel,
} from '../../types/claims/profilePermission';
import { SystemModules } from '../../types/claims/userManagerModules';
import { selectPermissions } from '../../utils/selectPermissions';

interface RequireAuthProps {
  moduleClaim?: SystemModules;
  minimumPermission?: MinimumPermission;
}
const RequireAuth = ({ minimumPermission, moduleClaim }: RequireAuthProps) => {
  const location = useLocation();
  const user = useAuthUser();
  const permissionsRequired: PermissionLevel[] | undefined =
    selectPermissions(minimumPermission); // * TODO: Cambiar nombre de la función

  let permission = false;
  if (permissionsRequired && moduleClaim !== undefined && user?.systemClaims) {
    permission =
      user.systemClaims[moduleClaim] &&
      permissionsRequired.includes(user.systemClaims[moduleClaim]);
  }
  console.log(permission);
  if (!permission) {
    return (
      <Navigate
        to="/401"
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

export default RequireAuth;
