import { Keyframes, keyframes } from '@emotion/react';

const pulseAnimation: Keyframes = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.2);
}
100% {
  transform: scale(1);
}`;

export default pulseAnimation;
