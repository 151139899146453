import axios, { AxiosError, AxiosInstance } from 'axios';
import { apiRRHHURL, mockAndDevelopment } from '../../app.config';

const rrhhClient: AxiosInstance = mockAndDevelopment()
  ? axios.create()
  : axios.create({
      baseURL: apiRRHHURL(),
      headers: {
        accept: '*/*',
        contentType: 'application/json',
      },
      paramsSerializer: {
        indexes: null,
      },
    });

export const add401Interceptor = (logoutFunct: () => void) => {
  rrhhClient.interceptors.response.use(undefined, (err: AxiosError) => {
    if (err.response?.status === 401) {
      logoutFunct();
    }
    return Promise.reject(err);
  });
};

export default rrhhClient;
