import { ReactNode } from 'react';
import { Card, Container, Modal, useTheme } from '@mui/material';
import { CancelIcon } from '../../icons/material';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
}

const GenericModal = ({ open, handleClose, children }: ModalProps) => {
  const { palette } = useTheme();

  return (
    <Modal
      open={open}
      disableAutoFocus
    >
      <Container
        maxWidth="xs"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Card
          sx={{
            padding: '35px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            position: 'relative',
          }}
        >
          <CancelIcon
            onClick={handleClose}
            sx={{
              position: 'absolute' as const,
              right: '18px',
              top: '18px',
              cursor: 'pointer',
              transition: 'all 0.5s ease',
              '&:hover': {
                color: palette.primary.main,
                transition: 'all 0.5s ease',
              },
            }}
          />
          {children}
        </Card>
      </Container>
    </Modal>
  );
};

export default GenericModal;
