export const rutRegex = /^(?:[0-9]*[kK]?)?$/;
export const rolRegex = /^[0-9]*$/;
export const userRegex = /^[0-9A-ZÑ]*$/;
export const partialEmailRegex = /^[%+-.0-9A-Z_a-z]*@?[-.0-9A-Za-z]*$/;
export const emailRegex = /^[%+-.0-9A-Z_a-z]+@[-.0-9A-Za-z]+\.[A-Za-z]{2,}$/;
export const nameRegex = /^(?:[-A-Za-zÁÉÍÑÓÚÜáéíñóúü]+(?:\s|$))*$/;

const removeExtraSpaces = (text: string): string[] =>
  text
    .replace(/\s+/g, ' ')
    .split(' ')
    .filter((word) => word !== '');

export const capitalizeText = (text: string): string =>
  removeExtraSpaces(text)
    .map(
      (word) =>
        word.charAt(0).toUpperCase() + word.substring(1).toLocaleLowerCase()
    )
    .join(' ');

// Ejemplos: terminales -> 251 - Los Espinos / trabajadores -> 4618 - Juan Álvarez Castro
export const codeAndTextFormat = (code: number, text: string): string => {
  if (code === 0) return capitalizeText(text);
  return `${code} - ${capitalizeText(text)}`;
};

export const avatarName = (name: string): string => {
  if (!name) return 'NN';
  const splittedName = removeExtraSpaces(name).map((word) =>
    word.toUpperCase()
  );

  switch (splittedName.length) {
    case 1:
      return splittedName[0].charAt(0);
    case 2:
    case 3:
      return `${splittedName[0].charAt(0)}${splittedName[1].charAt(0)}`;
    default:
      return `${splittedName[0].charAt(0)}${splittedName[2].charAt(0)}`;
  }
};

export const cleanRut = (rut: string): string =>
  rut.replaceAll('.', '').replaceAll('-', '');

export const formatRut = (rut: string): string => {
  if (rut === '') return rut;
  const dv = rut.slice(-1).toUpperCase();
  const body = rut.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${body}-${dv}`;
};

export const validateRut = (rut: string): boolean => {
  const dv = rut.slice(-1).toUpperCase();
  const body = rut.slice(0, -1);
  let sum = 0;
  let mul = 2;
  let index = body.length;
  while (index--) {
    sum += Number(body.charAt(index)) * mul;
    if (mul % 7 === 0) mul = 2;
    else mul++;
  }
  const res = sum % 11;
  return (
    (dv === '0' && res === 0) ||
    (dv === 'K' && res === 1) ||
    dv === `${11 - res}`
  );
};
