const customColors = {
  metbusPrimary: {
    100: '#cce9f0',
    200: '#99d4e1',
    300: '#66bed1',
    400: '#33a9c2',
    500: '#0093b3',
    600: '#00768f',
    700: '#00586b',
    800: '#003b48',
    900: '#001d24',
  },
  metbusSecondary: {
    100: '#fdecd3',
    200: '#fbd8a7',
    300: '#f9c57c',
    400: '#f7b150',
    500: '#f59e24',
    600: '#c47e1d',
    700: '#935f16',
    800: '#623f0e',
    900: '#312007',
  },
  background: {
    100: '#fdfdfd',
    200: '#fbfbfc',
    300: '#f8f9fa',
    400: '#f6f7f9',
    500: '#f4f5f7', // background-light
    600: '#c3c4c6',
    700: '#929394',
    800: '#626263',
    900: '#313131',
  },
  text: {
    100: '#cfd3d4',
    200: '#9ea7a8',
    300: '#6e7a7d',
    400: '#3d4e51',
    500: '#0d2226',
    600: '#0a1b1e',
    700: '#081417',
    800: '#050e0f',
    900: '#030708',
  },
  error: {
    100: '#f0d4ce',
    200: '#e1a99d',
    300: '#d17d6b',
    400: '#c2523a',
    500: '#b32709',
    600: '#8f1f07',
    700: '#6b1705',
    800: '#481004',
    900: '#240802',
  },
  blue: {
    100: '#ccdfed',
    200: '#99bfdb',
    300: '#669fca',
    400: '#337fb8',
    500: '#005fa6',
    600: '#004c85',
    700: '#003964',
    800: '#002642',
    900: '#001321',
  },
  yellow: {
    100: '#fff2d1',
    200: '#ffe4a3',
    300: '#ffd775',
    400: '#ffc947',
    500: '#ffbc19',
    600: '#cc9614',
    700: '#99710f',
    800: '#664b0a',
    900: '#332605',
  },
  creme: {
    100: '#fffff7',
    200: '#ffffee',
    300: '#fefee6',
    400: '#fefedd',
    500: '#fefed5',
    600: '#cbcbaa',
    700: '#989880',
    800: '#666655',
    900: '#33332b',
  },
};

export default customColors;

// alternativePrimary: {
//   100: "#f5fbfc",
//   200: "#ebf6f9",
//   300: "#e0f2f6",
//   400: "#d6edf3",
//   500: "#cce9f0",
//   600: "#a3bac0",
//   700: "#7a8c90",
//   800: "#525d60",
//   900: "#292f30"
// },
