import { AppUser } from './responses/loginResponse';

export enum ActionType {
  INITIALIZE = 'INITIALIZE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: AppUser | null;
  };
};

export type LoginAction = {
  type: ActionType.LOGIN;
  payload: {
    user: AppUser | null;
  };
};

export type LogoutAction = {
  type: ActionType.LOGOUT;
};

export type Action = InitializeAction | LoginAction | LogoutAction;
