import { LinearProgress } from '@mui/material';

const LoadingScreen = () => {
  return (
    <LinearProgress
      color="primary"
      sx={{
        width: '100%',
        height: '4px',
        marginTop: -4,
      }}
    />
  );
};

export default LoadingScreen;
