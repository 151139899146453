import { Container, Box, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from '../components/shared/Footer';
import Navbar from '../components/shared/Navbar';

const MainContainer = () => {
  return (
    <Container
      sx={{
        height: '100vh',
        width: '100vw',
        maxWidth: '100% !important',
        paddingX: '0px !important',
        paddingTop: '64px',
      }}
    >
      <Navbar />
      <Stack
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        height="94%"
        gap={4}
        sx={{
          overflowY: 'auto',
          bgcolor: 'background.paper',
          padding: '1rem',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Outlet />
        </Box>
        <Footer />
      </Stack>
    </Container>
  );
};

export default MainContainer;
