import { FC, ReactNode, useState } from 'react';
import { NavLink as RouterLink, matchPath } from 'react-router-dom';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListProps,
  ListSubheader,
} from '@mui/material';
import {
  ExpandLessIcon,
  ExpandMoreIcon,
  MoveToInboxIcon,
  StarBorderIcon,
} from '../icons/material';

interface Item {
  path?: string;
  icon?: ReactNode;
  children?: Item[];
  title: string;
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
  title: string;
}

const NavSection: FC<NavSectionProps> = (props) => {
  const { items, pathname, title, ...other } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      <List disablePadding>
        {items.map((item, index) => {
          const key = `${item.title}-${index}`;
          const exactMatch = item.path
            ? !!matchPath(
                {
                  path: item.path,
                  end: true,
                },
                pathname
              )
            : false;
          if (item.children) {
            const partialMatch = item.path
              ? !!matchPath(
                  {
                    path: item.path,
                    end: false,
                  },
                  pathname
                )
              : false;
            return (
              <>
                <ListItemButton onClick={handleClick}>
                  <ListItemIcon>
                    <MoveToInboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inbox" />
                  {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse
                  key={key}
                  in={open}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="div"
                    disablePadding
                  >
                    <ListItemButton
                      selected={partialMatch}
                      component={RouterLink}
                      to={item.path || ''}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <StarBorderIcon />
                      </ListItemIcon>
                      <ListItemText primary="Starred" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            );
          }
          return (
            <ListItemButton
              selected={exactMatch}
              key={key}
              component={RouterLink}
              to={item.path || ''}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          );
        })}
      </List>
    </List>
  );
};

export default NavSection;
