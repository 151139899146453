import { createContext, ReactNode, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import {
  initAuthAction,
  loginAction,
  logoutAction,
} from './AuthContextActionCreators';
import { authReducer } from './AuthContextReducer';
import { RequestTokenWithPermissions } from '../../api/authenticate';
import rosterClient from '../../api/clients/rosterClient';
import rrhhClient from '../../api/clients/rrhhClient';
import sgigoClient from '../../api/clients/sgigoClient';
import userManagerClient from '../../api/clients/userManagerClient';
import { use401Interceptor } from '../../api/interceptors/401Interceptors';
import setAuthorizationToken from '../../api/setAuthorizationToken';
import { State, AuthContextValue, JWT } from '../../types/authContext';
import { ClaimsResponse } from '../../types/claims/claimsResponse';
import { SnackbarUtils } from '../../utils/snackbarUtilsConfigurator';
import { isExpired } from '../../utils/dateRange';

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(true),
  logout: () => {},
});

const setSession = (authorizationToken: string | null): void => {
  if (authorizationToken) {
    localStorage.setItem('authorizationToken', authorizationToken);
    setAuthorizationToken(authorizationToken);
  } else {
    localStorage.removeItem('authorizationToken');
    setAuthorizationToken(null);
  }
};

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const initializeApp = () => {
    try {
      const authorization = localStorage.getItem('authorizationToken');
      if (authorization) {
        const claimsResponse: ClaimsResponse = jwtDecode(authorization);
        const {
          profileName,
          RosterCargaDeProgramacion,
          RosterResumenTrabajado,
          RosterVistaMensual,
          RosterVistaSemanal,
          Exchanges,
          HolidayMaintainer,
          ControlTaskHistory,
          ControlUpdater,
          UserMaintainer,
          SystemMaintainer,
          ProfileMaintainer,
          ActionMaintainer,
          RecipientMaintainer,
          GroupMaintainer,
          ConfigurationMaintainer,
          exp,
        } = claimsResponse;
        if (isExpired(exp)) dispatch(initAuthAction(null, false));
        else {
          setAuthorizationToken(authorization);
          dispatch(
            initAuthAction(
              {
                systemClaims: {
                  profileName,
                  RosterVistaMensual,
                  RosterVistaSemanal,
                  RosterCargaDeProgramacion,
                  RosterResumenTrabajado,
                  Exchanges,
                  HolidayMaintainer,
                  ControlTaskHistory,
                  ControlUpdater,
                  UserMaintainer,
                  SystemMaintainer,
                  ProfileMaintainer,
                  ActionMaintainer,
                  RecipientMaintainer,
                  GroupMaintainer,
                  ConfigurationMaintainer,
                },
              },
              true
            )
          );
        }
      } else dispatch(initAuthAction(null, false));
    } catch {
      SnackbarUtils.error('Ocurrió un error inicializando la aplicación');
      dispatch(initAuthAction(null, false));
    }
  };

  useEffect(() => {
    initializeApp();
  }, []);

  const login = async (
    userName: string,
    password: string
  ): Promise<boolean> => {
    const authorization = await RequestTokenWithPermissions({
      userName,
      password,
    });
    if (authorization === '') {
      dispatch(logoutAction());
      return false;
    }
    setSession(authorization);
    const claimsResponse: ClaimsResponse = jwtDecode(authorization);
    const {
      profileName,
      RosterCargaDeProgramacion,
      RosterResumenTrabajado,
      RosterVistaMensual,
      RosterVistaSemanal,
      Exchanges,
      HolidayMaintainer,
      ControlTaskHistory,
      ControlUpdater,
      UserMaintainer,
      SystemMaintainer,
      ProfileMaintainer,
      ActionMaintainer,
      RecipientMaintainer,
      GroupMaintainer,
      ConfigurationMaintainer,
    } = claimsResponse;
    dispatch(
      loginAction({
        systemClaims: {
          profileName,
          RosterVistaMensual,
          RosterVistaSemanal,
          RosterCargaDeProgramacion,
          RosterResumenTrabajado,
          Exchanges,
          HolidayMaintainer,
          ControlTaskHistory,
          ControlUpdater,
          UserMaintainer,
          SystemMaintainer,
          ProfileMaintainer,
          ActionMaintainer,
          RecipientMaintainer,
          GroupMaintainer,
          ConfigurationMaintainer,
        },
      })
    );
    return true;
  };

  const logout = async () => {
    const authorization = localStorage.getItem('authorizationToken') ?? '';
    await userManagerClient.get('/api/ConnectionLog/RegisterLogout', {
      headers: {
        Authorization: `Bearer ${authorization}`,
      },
      params: {
        systemId: 'PORTAL-METBUS-WEB',
        version: '',
      },
      withCredentials: true,
    });
    setSession(null);
    dispatch(logoutAction());
  };

  use401Interceptor(rrhhClient, logout);
  use401Interceptor(rosterClient, logout);
  use401Interceptor(sgigoClient, logout);
  use401Interceptor(userManagerClient, logout);

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        platform: 'JWT' as JWT,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
