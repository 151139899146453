import { State } from '../../types/authContext';
import { Action, ActionType } from '../../types/authContextActions';

export const authReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.INITIALIZE: {
      const { isAuthenticated, user } = action.payload;
      return {
        isInitialized: true,
        isAuthenticated,
        user,
      };
    }
    case ActionType.LOGIN: {
      const { user } = action.payload;
      const { isInitialized } = state;
      return {
        isInitialized,
        isAuthenticated: true,
        user,
      };
    }
    case ActionType.LOGOUT: {
      const { isInitialized } = state;
      return {
        isInitialized,
        isAuthenticated: false,
        user: null,
      };
    }
    default:
      return state;
  }
};
