import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import { mockAndDevelopment } from './app.config';
import AuthContextProvider from './contexts/authentication/AuthContextProvider';
import { ColorModeProvider } from './contexts/theme/ColorModeProvider';
import './main.css';

if (mockAndDevelopment()) {
  const { worker } = await import('./__mock__/msw/browser');
  await worker.start({
    waitUntilReady: true,
    onUnhandledRequest: 'bypass',
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ColorModeProvider>
    <AuthContextProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </AuthContextProvider>
  </ColorModeProvider>
);
