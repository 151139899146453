import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Hidden,
  Link,
  IconButton,
  Toolbar,
  Breadcrumbs,
  SvgIcon,
} from '@mui/material';

import { MenuIcon } from '../icons/material';
import { Item } from '../types/section';

interface DocsNavbarProps {
  onSidebarMobileOpen?: () => void;
  navbar: Item[];
}

const DocsNavbar: FC<DocsNavbarProps> = ({ onSidebarMobileOpen, navbar }) => (
  <Toolbar
    sx={{ width: { md: 'calc(100% - 45px)', lg: 'calc(100% - 360px)' } }}
  >
    <Box sx={{ flexGrow: 1 }} />
    <Breadcrumbs aria-label="breadcrumb">
      {navbar &&
        navbar.map((item) => (
          <Link
            key={item.title}
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            component={RouterLink}
            to={item.path ?? '/'}
          >
            {item.icon && (
              <SvgIcon
                sx={{ mr: 0.5 }}
                fontSize="inherit"
              >
                <path
                  d={typeof item.icon === 'string' ? item.icon : undefined}
                />
              </SvgIcon>
            )}
            {item.title}
          </Link>
        ))}
    </Breadcrumbs>

    <Hidden lgUp>
      <IconButton
        color="inherit"
        onClick={onSidebarMobileOpen}
      >
        <MenuIcon fontSize="small" />
      </IconButton>
    </Hidden>
  </Toolbar>
);

export default DocsNavbar;
