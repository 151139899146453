import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
// import { selectPermissions } from '../../utils/selectPermissions';
// import {
//   MinimumPermission,
//   PermissionLevel,
// } from '../../types/claims/profilePermission';
// import { SystemModules } from '../../types/claims/userManagerModules';
// import Login from '../../views/Login';

// TODO: Separar este Guard en dos: Uno que se dedique a verificar el usuario otro Guard que verifique los claims de los modulos que lo requieran

// TODO: Un vez que se separen las responsabilidades en dos Guards, la prop moduleClaim y minimumPermission deben ser ambas obligatorias.

// TODO: Cada vez que se agregue un nuevo modulo se deben crear los tipos, por ejemplo RosterModule. La propiedad moduleClaim pasará a un ser union type.

const AuthGuard = ({
  // moduleClaim,
  // minimumPermission,
  children,
}: React.PropsWithChildren) => {
  const [requestedLocation, setRequestedLocation] = useState<string | null>();
  // const user = useAuthUser();
  const isAuthenticated = useAuth();
  const location = useLocation();
  // const permissionsRequired: PermissionLevel[] | undefined =
  //   selectPermissions(minimumPermission);

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return (
      <Navigate
        to="/login"
        state={{ from: location }}
        replace
      />
    );
  }

  // let permission = false;
  // if (permissionsRequired && moduleClaim !== undefined && user?.systemClaims) {
  //   permission =
  //     user.systemClaims[moduleClaim] &&
  //     permissionsRequired.includes(user.systemClaims[moduleClaim]);
  // }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  // if (!permission) {
  //   return (
  //     <Navigate
  //       to="/unauthorized"
  //       state={{ from: location }}
  //       replace
  //     />
  //   );
  // }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AuthGuard;
