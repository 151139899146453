import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { CSSProperties, useState } from 'react';
import * as Yup from 'yup';
import { Button, Stack, TextField, Typography } from '@mui/material';
import ErrorMessage from './ErrorMessage';
import LoadingMessage from './LoadingMessage';
import changePassword from '../../../api/userManager/changePassword';
import {
  CheckCircleRoundedIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from '../../../icons/material';
import { MeResponse } from '../../../types/responses/loginResponse';

interface ChangePasswordProps {
  handleClose: () => void;
}

const iconStyle: CSSProperties = {
  aspectRatio: '1/1',
  margin: 'auto',
  minHeight: '200px',
  minWidth: '200px',
};

const ChangePasswordForm = ({ handleClose }: ChangePasswordProps) => {
  const identityData = JSON.parse(
    localStorage.getItem('identityData') ?? ''
  ) as MeResponse;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleCurrentPasswordVisibility = () =>
    setShowCurrentPassword(!showCurrentPassword);
  const handleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);

  const formik = useFormik({
    initialValues: { currentPassword: '', newPassword: '' },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(
        'Debe ingresar su contraseña actual'
      ),
      newPassword: Yup.string().required('Debe ingresar una nueva contraseña'),
    }),
    onSubmit: ({ currentPassword, newPassword }) => {
      setLoading(true);
      changePassword({
        userName: identityData.userName,
        currentPassword,
        newPassword,
      })
        .then(({ status }) => {
          if (status === 200) {
            setErrorMessage('');
            setSuccess(true);
          } else {
            setErrorMessage(
              'Ha ocurrido un error inesperado. Intente más tarde'
            );
            setSuccess(false);
          }
        })
        .catch((err: AxiosError) =>
          setErrorMessage(err.response?.data as string)
        )
        .finally(() => setLoading(false));
    },
  });

  if (loading) return <LoadingMessage />;

  if (errorMessage !== '') {
    return (
      <ErrorMessage
        errorMessage={errorMessage}
        iconStyle={iconStyle}
      />
    );
  }

  if (success) {
    return (
      <Stack>
        <Typography
          variant="h4"
          marginBottom={3}
        >
          ¡Contraseña Cambiada!
        </Typography>
        <CheckCircleRoundedIcon
          color="primary"
          style={iconStyle}
        />
        <Typography
          variant="body1"
          marginTop={4}
          marginX="auto"
        >
          Su contraseña fue cambiada exitosamente
        </Typography>
        <Button
          color="primary"
          fullWidth
          size="large"
          type="button"
          variant="outlined"
          onClick={handleClose}
          sx={{ marginTop: 2 }}
        >
          Cerrar
        </Button>
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography
        variant="h4"
        marginBottom={3}
      >
        Solicitud
      </Typography>
      <Typography variant="body1">
        Para cambiar su contraseña debe ingresar su contraseña actual y una
        nueva contraseña
      </Typography>
      <form
        onSubmit={formik.handleSubmit}
        style={{ position: 'relative' }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            name="currentPassword"
            label="Contraseña Actual"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            autoComplete="off"
            autoFocus
            fullWidth
            margin="normal"
            type={showCurrentPassword ? 'text' : 'password'}
            variant="outlined"
          />
          <Button
            onClick={handleCurrentPasswordVisibility}
            onTouchEnd={(e) => {
              e.preventDefault();
              handleCurrentPasswordVisibility();
            }}
            sx={{
              position: 'absolute',
              top: '25px',
              right: '5px',
              minWidth: 'fit-content',
              padding: 1,
              borderRadius: 50,
            }}
          >
            {showCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </Button>
        </div>
        <div>
          <TextField
            name="newPassword"
            label="Contraseña Nueva"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            autoComplete="off"
            fullWidth
            margin="normal"
            type={showNewPassword ? 'text' : 'password'}
            variant="outlined"
          />
          <Button
            onClick={handleNewPasswordVisibility}
            onTouchEnd={(e) => {
              e.preventDefault();
              handleNewPasswordVisibility();
            }}
            sx={{
              position: 'absolute',
              top: '105px',
              right: '5px',
              minWidth: 'fit-content',
              padding: 1,
              borderRadius: 50,
            }}
          >
            {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </Button>
        </div>
        <Button
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ marginTop: '18px' }}
        >
          Cambiar Contraseña
        </Button>
      </form>
    </Stack>
  );
};

export default ChangePasswordForm;
