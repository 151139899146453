import { Box, Drawer, Hidden } from '@mui/material';
import { FC, useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import MetbusLogo from '../icons/MetbusLogo';
import NavSection from '../components/NavSection';
import { Section } from '../types/section';

interface DocsSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  sections: Section[];
}
const DocsSidebar: FC<DocsSidebarProps> = (props) => {
  const { onMobileClose, openMobile, sections } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="90%"
      display="flex"
      flexDirection="column"
    >
      <Hidden lgUp>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <RouterLink to="/">
            <MetbusLogo
              sx={{
                width: 120,
              }}
            />
          </RouterLink>
        </Box>
      </Hidden>
      <Box p={2}>
        {sections.map((section) => (
          <NavSection
            key={section.title}
            pathname={location.pathname}
            sx={{
              '& + &': {
                mt: 3,
              },
            }}
            {...section}
          />
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="right"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              backgroundColor: 'background.default',
              width: 350,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="right"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !important',
              width: 350,
              flexShrink: 0,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DocsSidebar;
