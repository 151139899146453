export const mockAndDevelopment = () =>
  import.meta.env.DEV && import.meta.env.VITE_MOCK === 'true';

export const productive = () => import.meta.env.PROD;

export const apiRosterURL = () => import.meta.env.VITE_API_ROSTER;

export const apiSGIGOURL = () => import.meta.env.VITE_API_SGIGO;

export const apiTokenURL = () => import.meta.env.VITE_API_TOKEN;

export const apiUserManagerURL = () => import.meta.env.VITE_API_USERS;

export const apiMailingURL = () => import.meta.env.VITE_API_MAILING;

export const apiRRHHURL = () => import.meta.env.VITE_API_RRHH;

export const desktopWidth = '1100px';

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const adapterLocale = 'es-CL';

export const systemId = 'PORTAL-METBUS-WEB';
