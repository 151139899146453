import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { useEffect } from 'react';
import { RequestAuthorization } from '../authenticate';
import userManagerClient from '../clients/userManagerClient';
import setAuthorizationToken from '../setAuthorizationToken';

interface RetryConfig extends AxiosRequestConfig {
  retry?: boolean;
}

const refreshToken = (): Promise<AxiosResponse<void>> => {
  return userManagerClient.get('api/Token/RefreshToken', {
    withCredentials: true,
  });
};

export const use401Interceptor = (
  instance: AxiosInstance,
  logoutFunc: () => void
) => {
  let e401resolved = true;
  useEffect(() => {
    const intercepResponse = instance.interceptors.response.use(
      (res) => res,
      async (err: AxiosError) => {
        if (err.response?.status === 401) {
          if (e401resolved) {
            const config: RetryConfig = err.config ?? { retry: false };
            if (
              instance.defaults.headers.common.Authorization &&
              !config.retry
            ) {
              e401resolved = false;
              config.retry = true;
              const response = await refreshToken();
              if (response.status === 200 && config.headers) {
                const authRes = await RequestAuthorization();
                const authToken = authRes.data.token;
                localStorage.setItem('authorizationToken', authToken);
                setAuthorizationToken(authToken);
                config.headers.Authorization = `Bearer ${authToken}`;
                e401resolved = true;
                return instance(config);
              }
            }
          } else {
            logoutFunc();
            return Promise.reject();
          }
        }
        throw err;
      }
    );

    return () => {
      instance.interceptors.response.eject(intercepResponse);
    };
  }, []);
};
